<template>
    <div class="basicInformation">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item>
                <span>登录名</span>
                <br>
                <el-input v-model="ruleForm.username" disabled></el-input>
            </el-form-item>
            <el-form-item>
                <span>注册时间</span>
                <br>
                <el-input v-model="ruleForm.createTime" disabled></el-input>
            </el-form-item>
            <el-form-item prop="nickname">
                <span>姓名</span>
                <br>
                <el-input v-model="ruleForm.nickname"></el-input>
            </el-form-item>
            <el-form-item>
                <span>性别</span>
                <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="2"></el-option>
                    <el-option label="未设置" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <span>出生日期</span>
                <br>
                <el-date-picker :picker-options="pickerOptions" v-model="ruleForm.birthday" type="date"
                    placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <span>微信</span>
                <br>
                <el-input v-model="ruleForm.weChat"></el-input>
            </el-form-item>
            <el-form-item>
                <span>QQ</span>
                <br>
                <el-input v-model="ruleForm.qq"></el-input>
            </el-form-item>
            <el-form-item>
                <span>学历</span>
                <el-select v-model="ruleForm.education" placeholder="请选择学历">
                    <!-- <el-option label="初等教育" :value="1"></el-option>
                    <el-option label="中等教育" :value="2"></el-option>
                    <el-option label="高等教育" :value="3"></el-option> -->
                    <el-option label="小学及以下" :value="1"></el-option>
                    <el-option label="初中" :value="2"></el-option>
                    <el-option label="高中（含技校）" :value="3"></el-option>
                    <el-option label="大学（含大专）" :value="4"></el-option>
                    <el-option label="研究生及以上" :value="5"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <span>职业</span>
                <el-select v-model="ruleForm.occupation" placeholder="请选择职业">
                    <el-option label="国家机关、党群组织、企业、事业单位负责人" :value="1"></el-option>
                    <el-option label="专业技术人员" :value="2"></el-option>
                    <el-option label="办事人员和有关人员" :value="3"></el-option>
                    <el-option label="商业、服务业人员" :value="4"></el-option>
                    <el-option label="农、林、牧、渔、水利业生产人员" :value="5"></el-option>
                    <el-option label="生产、运输设备操作人员及有关人员" :value="6"></el-option>
                    <el-option label="军人" :value="7"></el-option>
                    <el-option label="学生" :value="8"></el-option>
                    <el-option label="不便分类的其他从业人员" :value="9"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-show="ruleForm.occupation === 8">
                <span>年级</span>
									<el-select v-model="ruleForm.grade" placeholder="请选择年级">
												<el-option label="小学1-6" :value="1"></el-option>
												<el-option label="初中1-3" :value="2"></el-option>
												<el-option label="高中1-3" :value="3"></el-option>
												<el-option label="大学1-4" :value="4"></el-option>
											</el-select>
										</el-form-item>
        </el-form>
        <div class="btn">
            <button @click="submitForm('ruleForm')">确定</button>
        </div>
    </div>
</template>

<script>
import { getDetail, updateUserinfo } from '@/api/personal'
export default {
    data() {
        return {
            ruleForm: {
                username: null,
                createTime: null,
                sex: 1,
                nickname: null,
                birthday: null,
                education: null,
                occupation: null,
                grade: null,
                weChat: null,
                qq: null
            },
            rules: {
                nickname: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { max: 15, message: '长度在 15 个字符以内', trigger: 'change' }
                ]
            },
            // 控制时间范围
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6; // 如果没有后面的-8.64e6就 是不可以选择今天的
                }
            }
        };
    },
    methods: {
        // 提交修改
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    let info = {}
                    info.sex = this.ruleForm.sex;
                    info.birthday = this.ruleForm.birthday;
                    info.nickname = this.ruleForm.nickname;
                    info.education = this.ruleForm.education;
                    info.occupation = this.ruleForm.occupation;
                    info.grade = this.ruleForm.grade;
                    info.weChat = this.ruleForm.weChat;
                    info.qq = this.ruleForm.qq;
                    info.id = Number(JSON.parse(localStorage.getItem('userInfo')).id);
                //     birthday: null,
                // education: null,
                // occupation: null,
                // grade: null,
                // weChat: null,
                // qq: null
                    // if (info.birthday !== null || info.birthday !== 'null') {
                    //   info.birthday = info.birthday + ' 00:00:00'  
                    // }
                    console.log(info)
                    const res = await updateUserinfo(JSON.stringify(info));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '修改成功！',
                            type: 'success'
                        });
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 1000);
                        this.getUserInfo();
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取用户基础信息
        async getUserInfo() {
            const res = await getDetail({ id: Number(JSON.parse(localStorage.getItem('userInfo')).id) });
            console.log(res);
            if (res.code === 200) {
                this.ruleForm = res.data;
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                // this.$forceUpdate();
            }
        },

    },
    created() {
        // console.log(JSON.parse(localStorage.getItem('userInfo')))
        this.getUserInfo();
    }
}
</script>

<style lang="scss" scoped>
.basicInformation {
    padding-top: 40px;
    height: 100%;
    width: 80%;
    overflow-y: scroll;
    height: calc(100% - 50px);
    margin: auto;
    // margin-bottom: 40px;
    // box-sizing: border-box;
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    // overflow-y: scroll;
    .el-form {
        // overflow-y: scroll;
        // height: calc(100% - 50px);

        span {
            font-size: 16px;
        }

        .el-select,
        .el-date-picker,
        .el-input {
            width: 100%;
            // height: 40px !important;
            // line-height: 40px !important;

        }
    }

    .btn {
        margin-top: 40px;

        button {
            width: 100%;
            height: 40px;
            background: var(--custom-color);
            border-radius: 4px;
            border: none;
            color: white;
            font-size: 14px;
        }
    }
}
</style>